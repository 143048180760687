import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EpiService } from '../../services/epi/epi.service';
import { ButtonComponent } from '../button/button.component';
import { DialogComponent } from '../dialog/dialog.component';

export type ConfirmDialogData = { header: string; text: string };

@Component({
	selector: 'cramo-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss'],
	standalone: true,
	imports: [DialogComponent, ButtonComponent],
})
export class ConfirmDialogComponent {
	private epiService = inject(EpiService);
	private readonly dialogRef = inject(MatDialogRef<ConfirmDialogComponent>);
	public data: ConfirmDialogData = inject(MAT_DIALOG_DATA);

	protected appData = toSignal(this.epiService.appData$, { requireSync: true });

	public closeModal(isConfirmed: boolean) {
		this.dialogRef.close(isConfirmed);
	}
}
